import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, Upload, message, Button, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAddPhotoSliderMutation } from "../../redux/Service/api";

const AddPhotoSlider = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [file, setFile] = useState(null);

  const [addPhotoSlider, { isLoading, isError, error }] =
    useAddPhotoSliderMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddOffer = async () => {
    if (!file) {
      message.error("Please upload a file");
      return;
    }
    const formData = new FormData();
    formData.append("photo", file);

    try {
      const res = await addPhotoSlider(formData);
      setFile(null);
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Photo added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Photo:", error);
      message.error("Error adding Photo. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Photo"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-8">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button type="primary" onClick={handleAddOffer} loading={isLoading}>
              add Photo
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddPhotoSlider;
