// import { Image } from 'antd'
import React from "react";

import logo from "../../assets/sargLogo.png";
import { Image } from "antd";

function SliderImg() {
  return (
    <div
      className="col-12 col-lg-6 mt-lg-5 slider-img justify-content-center"
      data-aos="fade-right"
    >
      <img src={logo} />
    </div>
  );
}

export default SliderImg;
