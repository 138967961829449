import {
  faDownload,
  faSquarePen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, Modal, Space, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import AddArticle from "./AddArticle";
import "./AddArticles.css";
import EditArticle from "./EditArticle";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  useDeleteArticleMutation,
  useGetAllArticlesQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import Linkify from "react-linkify";

const { confirm } = Modal;

function ArticlesControl() {
  // const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [articleIdForEdit, setArticleIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetAllArticlesQuery();

  const allArticles = allArticlesData?.data ?? null;

  const [
    deleteArticle,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteArticleMutation();

  const handleDeleteArticle = async (id) => {
    confirm({
      title: "Do you really want to delete this Article?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Article will be removed ",
      async onOk() {
        try {
          const res = await deleteArticle(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Article is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  const CustomLink = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <div className="gap-3 position-relative">
      <h2 className="main_heading">Articles</h2>
      {isAllArticlesLoading && <Loading />}
      {isAllArticlesError && (
        <p className="text-danger">Something went error</p>
      )}
      <div className="d-flex justify-content-center align-items-center mb-5 mt-4">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          add article
        </Button>
      </div>
      <section className="light">
        <div className="container py-2">
          {allArticles?.map((article) => (
            <article className="postcard light blue" key={article.id}>
              <div className="">
                <img
                  className="postcard__img"
                  src={article.photo}
                  alt="Title"
                />
              </div>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title blue">
                  <div>
                    {article.title}{" "}
                    <span className="fs-6">{article.author}</span>
                  </div>
                </h1>

                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">
                  <Linkify componentDecorator={CustomLink}>
                    {article.content}
                  </Linkify>{" "}
                </div>
                <ul className="postcard__tagbox gap-3">
                  <Button
                    danger
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faTrash} />}
                    onClick={() => {
                      handleDeleteArticle(article.id);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faSquarePen} />}
                    onClick={() => {
                      setOpenEditModal(true);
                      setArticleIdForEdit(article.id);
                      setRerenderEditKey((prev) => prev + 1);
                    }}
                  >
                    Update
                  </Button>
                </ul>
              </div>
            </article>
          ))}
        </div>
      </section>

      <EditArticle
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        articleIdForEdit={articleIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />

      <AddArticle
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
    </div>
  );
}

export default ArticlesControl;
