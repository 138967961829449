import React, { useEffect } from "react";
import "./AboutUs.css";
import { Image } from "react-bootstrap";

// import logo from "../../assets/logo.jpg";
// import logo from "../../assets/headingLightLogo.png";
// import logo from "../../assets/headingDarkLogo.png";
import logo from "../../assets/sargLogo.png";
import Aos from "aos";
import "aos/dist/aos.css"; // استيراد ملف الستايل الخاص ب AOS
import Profile from "../Profile/Profile";
import { useGetAllAboutsQuery } from "../../redux/Service/api";
import { Spin } from "antd";
import { language } from "../../language/Language";

function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const {
    data: allAboutsData,
    isError: isAllAboutsError,
    isLoading: isAllAboutsLoading,
    error: allAboutsError,
  } = useGetAllAboutsQuery();

  const allAbouts = allAboutsData?.data ?? null;
  console.log("allAbouts", allAbouts);

  return (
    <div
      className="aboutUs mt-120"
      data-aos="fade-up"
      style={{ direction: "rtl" }}
    >
      <h1 className="heading gap-3 d-flex align-items-center justify-content-center mb-4 mb-lg-0">
        <Image className="col-2 rounded-5 heading" src={logo} />
        {language === "en" ? "About us" : "نبذة عن سرج"}
      </h1>

      <Profile
        allAbouts={allAbouts}
        isAllAboutsError={isAllAboutsError}
        isAllAboutsLoading={isAllAboutsLoading}
      />

      <div className="d-flex flex-wrap row mb-5 mb-md-5" data-aos="fade-up">
        <div className="col-12 col-lg-6" data-aos="fade-left">
          <div className="text_end fw-bold mt-lg-5 pt-lg-5">
            {isAllAboutsLoading && <Spin />}
            {allAbouts?.map((about) => (
              <p
                key={about.id}
                className="fs-5 text-center new-text-color"
                style={{
                  lineHeight: 1.5,
                  marginTop: "-1rem",
                  fontWeight: "400",
                }}
              >
                {about.about_us}
              </p>
            ))}
            {isAllAboutsError && (
              <p
                className="fs-5 text-center new-text-color"
                style={{ lineHeight: 1.5, marginTop: "-1rem" }}
              >
                مصنع سرج يعتبر من اهم المصانع فى الوطن العربي ومصر لصناعه ودباغه
                الجلود وتم انشاء الشركه على يد الخبراء المعنيين بالصناعه ومرعاه
                التدخل التكنولوجى للارتقاء بمستوى الصناعه فى مصر
              </p>
            )}
          </div>
        </div>
        {allAbouts?.map((about) => (
          <div className="col-12 col-lg-6 videoAbout rounded-1">
            <iframe
              src={about.video_link}
              width="100%"
              height="350"
              style={{
                border: "none",
                overflow: "hidden",
                borderRadius: "1rem",
              }}
              scrolling="no"
              frameBorder="0"
              title="about us video"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        ))}
        {isAllAboutsError && (
          <div className="col-12 col-lg-6 videoAbout rounded-1">
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fsargtannery%2Fvideos%2F783377661793493%2F&show_text=false&width=560&t=0"
              width="100%"
              height="350"
              style={{
                border: "none",
                overflow: "hidden",
                borderRadius: "1rem",
              }}
              scrolling="no"
              frameBorder="0"
              title="about us video"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default AboutUs;
