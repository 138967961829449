import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { reduxLogin } from "./redux/Slices/authSlice";
import { ToDarkMode, ToLightMode } from "./redux/Slices/DarkModeSlice";

function App() {
  const dispatch = useDispatch();

  if (localStorage.getItem("sargUserToken")) {
    dispatch(reduxLogin());
  }

  if (localStorage.getItem("sargDarkMode") == "true") {
    document.body.classList.add("dark-mode");
    dispatch(ToDarkMode());
  } else {
    document.body.classList.remove("dark-mode");
    dispatch(ToLightMode());
  }

  return <RouterProvider router={router} />;
}

export default App;
