import { Container, Image, Row } from "react-bootstrap";
import "./Articles.css";
// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import admin from "../../assets/2.png";
import { useEffect } from "react";
import Aos from "aos";
import { useGetAllArticlesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { language } from "../../language/Language";

const Articles = () => {
  const navigate = useNavigate();
  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetAllArticlesQuery();

  const allArticles = allArticlesData?.data ?? null;

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="blog position-relative">
      {/* {inHome ? ( */}
      <div className="aboutUs py-5 mb-3">
        <h1 className="heading gap-3 d-flex align-items-center justify-content-center">
          {language === "en" ? "Sarg articles" : "مقالات سرج"}
          <Image className="col-2 rounded-5 heading" src={logo} />
        </h1>
      </div>

      {isAllArticlesLoading && <Spin />}

      <main className="mt-lg-5">
        <Container>
          <Row>
            <section data-aos="fade-right new-light-bg-color">
              {allArticles?.map((article) => (
                <article
                  className="postcard new-light-bg-color"
                  key={article.id}
                >
                  <div className="new-light-bg-color">
                    <img
                      className="postcard__img my-3 new-light-bg-color"
                      src={article.photo}
                      alt="Title"
                    />
                  </div>
                  <div className="postcard__text t-dark new-light-bg-color">
                    <h1 className="postcard__title new-heading-color">
                      {article.title}
                    </h1>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt">
                      <span className="userName">{article.author}</span>
                    </div>
                    <div className="">
                      <p className="author new-text-color mt-2">
                        {article.content.slice(0, 100).trim()}...
                        <span
                          className="mx-1 new-heading-color"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/Articles/${article.id}`)}
                        >
                          {language === "en" ? "more" : "المزيد"}
                        </span>
                      </p>
                    </div>
                  </div>
                </article>
              ))}
            </section>
            {isAllArticlesError && (
              <section className="light" data-aos="fade-left">
                <article className="postcard light blue new-light-bg-color">
                  <a className="postcard__img_link" href="#">
                    <img
                      className="postcard__img my-3"
                      src={admin}
                      alt="Image Title"
                    />
                  </a>
                  <div className="postcard__text t-dark ">
                    <h1 className="postcard__title blue">
                      <a href="#">كن دائما مبتكر للوصول الى القمه </a>
                    </h1>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt">
                      <span className="userName">محمود سيرج</span>
                    </div>
                    <div className="">
                      <p className="author">
                        دائما كن انت وابتكر طرق مختلفه للتعلم والتجربه
                      </p>
                    </div>
                  </div>
                </article>
              </section>
            )}
          </Row>
        </Container>
      </main>
    </section>
  );
};

export default Articles;
