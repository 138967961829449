import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, message, Button, Input } from "antd";
import { useAddJobMutation } from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";

const AddJob = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");

  const [addJob, { isLoading, isError, error }] = useAddJobMutation();

  const handleAddJob = async () => {
    if (!title) {
      message.error("Please add the arabic title");
      return;
    }
    if (!titleEN) {
      message.error("Please add the english title");
      return;
    }
    if (!content) {
      message.error("Please add the arabic description");
      return;
    }
    if (!contentEN) {
      message.error("Please add the english description");
      return;
    }

    const jobBody = {
      title: title,
      title_en: titleEN,
      description: content,
      description_en: contentEN,
    };

    try {
      const res = await addJob(jobBody);

      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Job added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Job:", error);
      message.error("Error adding Job. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Job"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={700}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="عنوان الوظيفة"
                style={{ direction: "rtl", textAlign: "right" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextArea
                placeholder="الوصف"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="author job in english"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
              <TextArea
                placeholder="Content"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="g-2">
            <Button type="primary" onClick={handleAddJob} loading={isLoading}>
              add Job
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddJob;
