import React from "react";
import "./Products.css";
import card from "../../assets/SomeProducts2.jpg";
import { Image, Row } from "react-bootstrap";
// import { Image, Row } from 'antd'
// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import { useGetAllCategoriesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";

function Products() {
  const {
    data: allitemsData,
    isError: isAllitemsError,
    isLoading: isAllitemsLoading,
    error: allitemsError,
  } = useGetAllCategoriesQuery();

  const allItems = allitemsData?.data ?? null;
  return (
    <div className="products position-relative">
      <div className="container py-5">
        <h1 className="heading gap-3 d-flex align-items-center justify-content-center pb-5">
          {language === "en" ? "Sarg products" : "منتجات سرج"}
          <Image className="col-2 rounded-5 heading" src={logo} />
        </h1>
        {isAllitemsLoading && <Loading />}
        <Row className="gy-4 gx-1">
          {allItems?.map((item) => (
            <div
              key={item.id}
              className="col-12 col-sm-6 col-md-6 col-lg-3 cardd rounded-4 "
            >
              <Image
                src={item.photo}
                className="w-100 rounded-4"
                style={{ height: "320px" }}
              />
              <h5 className="text-center fw-light px-2 pt-1">{item.details}</h5>
            </div>
          ))}
          {isAllitemsError && (
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 cardd rounded-4 ">
              <Image
                src={card}
                className="w-100 rounded-4"
                style={{ height: "320px" }}
              />
            </div>
          )}
        </Row>
      </div>
    </div>
  );
}

export default Products;
