import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  faSquareFacebook,
  faSquareInstagram,
  faYoutube,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { language } from "../../language/Language";

import { Link } from "react-router-dom";

import "./Footer.css";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const route = useNavigate();

  return (
    <footer className=" pt-5 box_shadow new-light-bg-color new-text-color">
      <div className="footer container">
        <div className="row px-4 px-lg-0 mb-2">
          {/* col-2 */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "en" ? "Company" : "شركة"}</h6>
            </li>
            <li>
              <Link className="new-text-color" to="/">
                {language === "en" ? "About Us" : "من نحن"}
              </Link>
            </li>
            <li>
              <Link className="new-text-color" to="/Connect">
                {language === "en" ? "Contact Us" : "تواصل معانا"}
              </Link>
            </li>
          </ul>
          {/* col-2 */}

          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "en" ? "Important Links" : "روابط مهمة"}</h6>
            </li>
            <li>
              <Link className="new-text-color" to="/Products">
                {language === "en" ? "Our Products" : "منتجاتنا"}
              </Link>
            </li>
            <li>
              <Link className="new-text-color" to="/Articles">
                {language === "en" ? "Articles" : "مقالات"}
              </Link>
            </li>
          </ul>
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-3 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>
                {language === "en" ? "Do you want help?" : "تريد مساعدة؟"}
              </h6>
            </li>
            <li>
              <Link to="mailto:info@sargtannery.com" className="new-text-color">
                <FontAwesomeIcon icon={faEnvelope} /> sarg@sargtannery.com
              </Link>
            </li>
            <li>
              <Link to="mailto:info@sargtannery.com" className="new-text-color">
                <FontAwesomeIcon icon={faEnvelope} /> info@sargtannery.com
              </Link>
            </li>
            <li>
              <span className="new-text-color">
                <FontAwesomeIcon icon={faPhone} />{" "}
                <span style={{ fontSize: "1.15rem" }} dir="ltr">
                  +201001641266
                </span>
              </span>
            </li>
          </ul>
          {/* col-4 */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-4 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "en" ? "Our Location" : "موقعنا"}</h6>
            </li>
            <li>
              <Link
                to="https://maps.app.goo.gl/X8zPBVH8xyYRj88p6"
                target="_blank"
                className="gap-2 d-flex flex-nowrap new-text-color"
              >
                <i className="bi bi-geo-alt"></i>
                {language === "en"
                  ? "Building M3. ST 10, Robbiki Leather City, Egypt"
                  : "مبنى M3. ش 10 - مدينة الجلود بالروبيكي - مصر"}
              </Link>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.1655320723635!2d31.7621559!3d30.175265099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457fb8e5e02f92f%3A0xa679cfc8ccc6def5!2z2YXYr9io2LrYqSDYs9ix2KwgU2FyZyBUYW5uZXJ5!5e0!3m2!1sar!2seg!4v1707425091582!5m2!1sar!2seg" height="250" style={{ border: "0", width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              <iframe
                async
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.1655320723635!2d31.7621559!3d30.175265099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457fb8e5e02f92f%3A0xa679cfc8ccc6def5!2z2YXYr9io2LrYqSDYs9ix2KwgU2FyZyBUYW5uZXJ5!5e0!3m2!1sar!2seg!4v1707425091582!5m2!1sar!2seg"
                height="250"
                style={{ border: "0", width: "100%" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
        </div>
        <div className="row px-4 px-lg-0 justify-content-around d-flex">
          <div className="col-12 col-lg-4 text-start-fremwork ">
            <h6 className="heading_meada new-text-color">
              {language === "en" ? "Follow us" : "تابعنا"}
            </h6>
            <div className="meada">
              <a
                href="https://www.facebook.com/sargtannery/"
                target="_blank"
                rel="noopener noreferrer"
                className="new-heading-color"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
              {/* <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faSquareInstagram} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <p className="m-0 p-3 copay new-text-color">
        {language === "en"
          ? "Ⓒ All rights reserved to the company"
          : "Ⓒ جميع الحقوق محفوظة لشركة"}
        <a
          href="https://prosofteg.com"
          target="_blank"
          rel="noopener noreferrer"
          className="ms-1 me-1 mt-0 mb-0 new-heading-color"
        >
          {" "}
          Sarg
        </a>
      </p>
    </footer>
  );
};

export default Footer;
