import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Input, message, Modal, Space, Spin } from "antd";

import {
  useEditJobMutation,
  useGetFullJobDetailsQuery,
} from "../../redux/Service/api";

import TextArea from "antd/es/input/TextArea";

const EditJob = ({
  setOpenEditModal,
  openEditModal,
  tipIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);

  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");

  const {
    data: allJobData,
    isError: isAllJobError,
    isLoading: isAllJobLoading,
    error: allJobError,
  } = useGetFullJobDetailsQuery(tipIdForEdit);

  const allJobDetails = allJobData?.data ?? null;

  useEffect(() => {
    setTitle(allJobDetails?.title);
    setTitleEN(allJobDetails?.title_en);
    setContent(allJobDetails?.description);
    setContentEN(allJobDetails?.description_en);
  }, [allJobDetails, rerenderEditKey]);

  const [editJob, { isLoading: isEditLoading, isError: isEditError, error }] =
    useEditJobMutation();

  const handleEditJob = async () => {
    if (!title) {
      message.error("Please add the arabic title");
      return;
    }
    if (!titleEN) {
      message.error("Please add the english title");
      return;
    }
    if (!content) {
      message.error("Please add the arabic content");
      return;
    }
    if (!contentEN) {
      message.error("Please add the english author name");
      return;
    }

    const jobBody = {
      title: title,
      title_en: titleEN,
      description: content,
      description_en: contentEN,
    };

    try {
      const res = await editJob({ id: tipIdForEdit, jobBody });

      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Job added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Job:", error);
      message.error("Error adding Job. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Job"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer
    >
      {isAllJobLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllJobError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="عنوان الوظيفة"
                style={{ direction: "rtl", textAlign: "right" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextArea
                placeholder="الوصف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="author name in english"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />

              <TextArea
                placeholder="Content"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditJob}
              loading={isEditLoading}
            >
              edit JOb
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditJob;
