import { Image } from "react-bootstrap";
import "./SaddleNews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import { useEffect } from "react";
import Aos from "aos";
import { useGetAllNewsQuery } from "../../redux/Service/api";
import { Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { language } from "../../language/Language";

const Testimonials = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 }); // تهيئة AOS وتحديد مدة الانتقال الافتراضية
  }, []);

  const {
    data: allNewsData,
    isError: isAllNewsError,
    isLoading: isAllNewsLoading,
    error: allNewsError,
  } = useGetAllNewsQuery();

  const allNews = allNewsData?.data ?? null;

  return (
    <>
      <div
        className="aboutUs py-5 mb-2 mb-lg-5"
        data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"
      >
        <h1 className="heading gap-3 d-flex align-items-center justify-content-center">
          {language === "en" ? "Sarg news" : "اخبار سرج"}

          <Image className="col-2 rounded-5 heading" src={logo} />
        </h1>
      </div>
      <Row className="row justify-content-center column-gap-4 sarg_news">
        {isAllNewsLoading && <Spin />}
        {allNews?.map((news) => (
          <figure
            key={news.id}
            className="snip1208 new-light-bg-color col-11 col-sm-5 pb-2 col-md-5 col-lg-3 rounded-4 "
            data-aos="fade-left"
            // data-aos-anchor-placement="top-bottom"
          >
            <img
              src={news.photo}
              alt="sample66"
              style={{ height: "12rem" }}
              width="100%"
            />

            <figcaption>
              <span className="d-flex justify-content-end align-items-center">
                <span className="user">{news.author}</span>
              </span>
              <h5 className="new-heading-color my-2 description_title">
                {/* {news.title} */}
                {news.title.slice(0, 55).trim()}...
              </h5>
              <p className="new-text-color description_news">
                {/* {news.content} */}
                {news.content.slice(0, 50).trim()}...
              </p>
              <button
                className="new-bg-orange-color  rounded-2"
                onClick={() => navigate(`/News/${news.id}`)}
              >
                {language === "en" ? "Read more" : "اقرأ المزيد"}
              </button>
            </figcaption>
          </figure>
        ))}
        {isAllNewsError && (
          <figure
            className="snip1208 col-lg-4"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <img src={logo} alt="sample66" />

            <figcaption>
              <span className="d-flex justify-content-end align-items-center">
                <span className="user">محمود سرج</span>
              </span>
              <h5>
                {language === "en"
                  ? "Our ambition should be to achieve 100 billion from the industrial sector"
                  : "يجب أن يكون طموحنا تحقيق 100 مليار من قطاع الصناعة"}
              </h5>
              <p>
                {language === "en"
                  ? `Dr. Mahmoud Sarg, head of the Leather Export Council, said that we are in an economic crisis,
and to get through it, there are two sectors through which the crisis can be overcome. 
Pointing out that the tourism sector is out of competition`
                  : `قال الدكتور محمود سرج، رئيس المجلس التصديري للجلود، إننا في أزمة اقتصادية، ولكي نعبرها، هناك قطاعين يمكن من خلالهما تجاوز الأزمة، مشيرا إلى أن قطاع السياحة خارج المنافسة`}
              </p>
            </figcaption>
          </figure>
        )}
      </Row>
    </>
  );
};

export default Testimonials;
