import { configureStore } from "@reduxjs/toolkit";
import { api } from "./Service/api";
import authReducer from "./Slices/authSlice";
import DarkModeReducer from "./Slices/DarkModeSlice";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,

    auth: authReducer,
    DarkMode: DarkModeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
export default store;
