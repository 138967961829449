import { Spin } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import profile_admin from "../../assets/profileDark.png";
import profile_admin2 from "../../assets/lightPGP.png";
import "./Profile.css";
import { language } from "../../language/Language";
import { useSelector } from "react-redux";
function Profile({ allAbouts, isAllAboutsError, isAllAboutsLoading }) {
  const { mode } = useSelector((st) => st.DarkMode);

  return (
    <Container className="d-flex me-lg-5 aboutUs_content flex-wrap justify-content-center justify-content-lg-start align-items-center mt-4 py-lg-5 ">
      <div className="home__image">
        {allAbouts?.map((about, idx) => (
          <img key={idx} src={about.photo} className=" rounded-3" alt=""></img>
        ))}
        {isAllAboutsError && (
          <img
            src={mode ? profile_admin : profile_admin2}
            className=" rounded-3"
            alt=""
          ></img>
        )}
      </div>
      {isAllAboutsLoading && <Spin />}
      {allAbouts?.map((about) => (
        <div className="home__content col-lg-8 mx-5" key={about.id}>
          <div className=" language_about">
            <p className="new-heading-color mb-3 fs-4 fw-bold">
              {about.manager_name}
            </p>
            <h3 className="new-text-color fw-bold">{about.manager_position}</h3>
            <p className="new-text-color">{about.manager_information}</p>
          </div>
        </div>
      ))}
      {isAllAboutsError && (
        <div className="home__content col-lg-5 mx-5">
          <div className="text-center text-lg-end">
            <p className="new-heading-color mb-3 fs-4 fw-bold">
              {" "}
              {language === "en" ? "Engineer Mahmoud Sarg" : "مهندس محمود سرج"}
            </p>
            <h3 className="new-text-color fw-bold">
              {language === "en"
                ? "Chairman of the Board of Directors of Sarj Company"
                : "رئيس مجلس إدارة شركة سرج"}
            </h3>
            <p className="new-text-color">
              {language === "en"
                ? `He is the Chairman of the Leather Export Council and a member of the Federation of Industries for the Tanning Chamber
Leather`
                : `و رئيس المجلس التصديري للجلود وعضو اتحاد الصناعات عن غرفة دباغة الجلود`}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Profile;
