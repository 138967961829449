import AppTitles from "./Carousel";
import SliderImg from "./SliderImg";
// import { Row } from 'antd'
import "./Slider.css";

function Slider() {
  return (
    <div className="d-flex flex-wrap row home-heading flex-column-reverse flex-lg-row">
      <AppTitles data-aos="fade-left" />
      <SliderImg data-aos="fade-right" />
    </div>
  );
}

export default Slider;
